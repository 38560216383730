// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const azureIdOpen = createSlice({
  name:"azureIdOpen",
  initialState:"",
  reducers:{
    UpdateAzureIdOpenAction(state,action){
      return (action.payload)
    },
  }
}) 
export default azureIdOpen.reducer;
export const {UpdateAzureIdOpenAction}= azureIdOpen.actions;