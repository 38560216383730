import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const ExpiredURL: FC = () => {
    return (
        <>
            {/* begin::Title */}
            <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops, this link is expired</h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='fw-semibold fs-6 text-gray-500 mb-7'>This URL is not valid anymore.</div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className='mb-3'>
                <img
                    src={toAbsoluteUrl('/media/auth/expired.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                />
            </div>
            {/* end::Illustration */}

            <div className={'d-flex justify-content-evenly'}>
                {/* begin::Link */}
                <div className='mb-0'>
                    <Link to='/auth' className='btn btn-sm btn-primary'>
                        Login
                    </Link>
                </div>
                <div className='mb-0'>
                    <Link to='/auth/forgot-password' className='btn btn-sm btn-primary'>
                        Forgot Password
                    </Link>
                </div>
                {/* end::Link */}
            </div>
        </>
    )
}

export {ExpiredURL}
