import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AmazonProductsPage from "../modules/amazon-product/AmazonProductsPage";
import AmazonAccountsPage from "../modules/amazon-account/AmazonAccountsPage";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    const RolesPage = lazy(() => import('../modules/roles/RolesPage'))
    const BuyersPage = lazy(() => import('../modules/buyers/BuyersPage'))
    const UsersPage = lazy(() => import('../modules/users/UsersPage'))
    const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
    const AmazonProductsPage = lazy(() => import('../modules/amazon-product/AmazonProductsPage'))
    const AmazonAccountsPage = lazy(() => import('../modules/amazon-account/AmazonAccountsPage'))

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                <Route
                    path='builder'
                    element={
                        <SuspensedView>
                            <BuilderPageWrapper/>
                        </SuspensedView>
                    }
                />
                <Route path='menu-test' element={<MenuTestPage/>}/>
                {/* Lazy Modules */}
                <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='account/*'
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='products/*'
                    element={
                        <SuspensedView>
                            <ProductsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='roles/*'
                    element={
                        <SuspensedView>
                            <RolesPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='buyers/*'
                    element={
                        <SuspensedView>
                            <BuyersPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='users/*'
                    element={
                        <SuspensedView>
                            <UsersPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='amazon/*'
                    element={
                        <SuspensedView>
                            <AmazonProductsPage/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='aws/*'
                    element={
                        <SuspensedView>
                            <AmazonAccountsPage/>
                        </SuspensedView>
                    }
                />

                {/*<Route*/}
                {/*    path='apps/user-management/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <UsersPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
