/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

export function AsideMenuMain() {
    const intl = useIntl()
    const {currentUser} = useAuth();

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='element-11'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            />
            {/*<AsideMenuItem to='/builder' icon='switch' title='Layout Builder' />*/}
            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content pt-8 pb-2'>*/}
            {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<AsideMenuItemWithSub to='/crafted/pages' title='Pages' icon='gift'>*/}
            {/*  <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/overview' title='Detail' hasBullet={true} />*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
            {/*    <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />*/}
            {/*    <AsideMenuItem*/}
            {/*      to='/crafted/pages/profile/connections'*/}
            {/*      title='Connections'*/}
            {/*      hasBullet={true}*/}
            {/*    />*/}
            {/*  </AsideMenuItemWithSub>*/}

            {/*  <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
            {/*    <AsideMenuItem*/}
            {/*      to='/crafted/pages/wizards/horizontal'*/}
            {/*      title='Horizontal'*/}
            {/*      hasBullet={true}*/}
            {/*    />*/}
            {/*    <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
            {/*  </AsideMenuItemWithSub>*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' icon='profile-circle'>*/}
            {/*  <AsideMenuItem to='/account/overview' title='Detail' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/account/settings' title='Settings' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>*/}
            {/*  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' icon='element-plus'>*/}
            {/*  <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}
            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content pt-8 pb-2'>*/}
            {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>*/}
            {/*  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
            {/*  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
            {/*</AsideMenuItemWithSub>*/}

            {(currentUser?.hasPermission('can-view-amazon-accounts') && currentUser?.hasPermission('can-synchronize-products')) &&
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Amazon Products</span>
                    </div>
                </div>
            }

            {currentUser?.hasPermission('can-synchronize-products') &&
                <AsideMenuItem to='amazon/products' icon='shield-tick' title='Associate Products'/>
            }

            {currentUser?.hasPermission('can-view-amazon-accounts') &&
                <AsideMenuItem to='aws/accounts' icon='shield-tick' title='Manage Account'/>
            }

            {(currentUser?.hasPermission('can-view-products')) &&
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Products</span>
                        </div>
                    </div>
                    <AsideMenuItem to='products/list' icon='shield-tick' title='Manage Products'/>
                </>
            }

            {(currentUser?.hasPermission('can-view-roles')) &&
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Authorization</span>
                        </div>
                    </div>
                    <AsideMenuItem to='roles/list' icon='shield-tick' title='Manage Role'/>
                </>
            }


            {(currentUser?.hasPermission('can-view-users') && currentUser?.hasPermission('can-view-buyers')) &&
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>User Management</span>
                    </div>
                </div>
            }

            {(currentUser?.hasPermission('can-view-buyers')) &&
                <AsideMenuItem to='buyers/list' icon='shield-tick' title='Manage Buyers'/>
            }

            {(currentUser?.hasPermission('can-view-users')) &&
                <AsideMenuItem to='users/list' icon='shield-tick' title='Manage Users'/>
            }

            {/*<AsideMenuItem to='/apps/user-management/users' icon='shield-tick' title='User management' />*/}

            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content'>*/}
            {/*    <div className='separator mx-1 my-4'></div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='menu-item'>*/}
            {/*  <a*/}
            {/*    target='_blank'*/}
            {/*    className='menu-link'*/}
            {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
            {/*  >*/}
            {/*    <span className='menu-icon'>*/}
            {/*      <KTIcon iconName='document' className='fs-2' />*/}
            {/*    </span>*/}
            {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
            {/*  </a>*/}
            {/*</div>*/}
        </>
    )
}
