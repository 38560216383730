import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {useSelector} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {useMsal} from '@azure/msal-react'
import {PublicClientApplication} from '@azure/msal-browser'
import axios from 'axios'
const url_api = process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, '')
// const url_api = process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, '') + '/'
// let currentAccount

var tenantid: any
var clientid: any
// import { useNavigate } from 'react-router-dom'
// import { useHistory } from 'react-router-dom';
// import * as msal from '@azure/msal-browser';

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: (item: any) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: (item: any) => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  const data = useSelector((state: any) => state.azureId)
  tenantid = data.tenantId
  clientid = data.clientId
  console.log('urgygyrey:::::::::', data)
  return useContext(AuthContext)
}

const storedAzureCredentials = localStorage.getItem('microsoftAzure')
if (storedAzureCredentials) {
  // const azureCredentials = JSON.parse(storedAzureCredentials)
  // tenantid = azureCredentials.tenantId
  // clientid = azureCredentials.clientId
}

const msalConfig = {
  auth: {
    clientId: clientid,
    authority: `https://login.microsoftonline.com/${tenantid}`,
    // clientId: "fff6db42-6c06-4093-9ff0-9ee912f0027d",
    // authority: `https://login.microsoftonline.com/9eb2f756-f10a-4dc1-b735-3fd1ff15b469`,
    // redirectUri: 'http://localhost:3011',
    // postLogoutRedirectUri: 'http://localhost:3011',
    redirectUri: url_api,
    postLogoutRedirectUri :url_api
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}
const msalInstance = new PublicClientApplication(msalConfig)
const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const {instance} = useMsal()
  // const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [checkLogout, setCheckLogout] = useState(false)
  const [isCheckLogout, setIsCheckLogout] = useState(false)
  const [logoutInProgress, setLogoutInProgress] = useState(false)
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async (item: any) => {
    // debugger

    // Clear MSAL cache
    // instance.logoutPopup().catch((e) => {
    //   console.error(e)
    // })
    // instance.clearCache();
    //  await instance.logout();

    // window.location.href = 'http://localhost:3011'
    //   instance.logoutRedirect({
    //   postLogoutRedirectUri: 'http://localhost:3011', // Redirect URI after logout
    // });'
    if (item != 'false') {
      setCheckLogout(true)
    }

    // Clear other session data if any
    // localStorage.removeItem('yourCustomSessionData')
    // sessionStorage.removeItem('yourCustomSessionData')

    // Redirect to home or login page
    // window.location.href = '/'
  }

  // const msalConfig = {
  //   auth: {
  //     clientId: 'fff6db42-6c06-4093-9ff0-9ee912f0027d',
  //     authority: 'https://login.microsoftonline.com/9eb2f756-f10a-4dc1-b735-3fd1ff15b469',
  //     redirectUri: 'http://localhost:3011/',
  //     // redirectUri: process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, '')
  //   },
  //   cache: {
  //     cacheLocation: 'sessionStorage',
  //     storeAuthStateInCookie: false,
  //   }
  // };

  // const msalInstance = new msal.PublicClientApplication(msalConfig);

  // Example function to sign out all accounts
  async function signOutAllAccounts() {
    // const logoutRequest = {
    //   account: msalInstance.getAllAccounts()[0], // Assuming you have only one account
    // };
    // msalInstance.logoutRedirect();

    // console.log("qwqwqwqwqw::",msalInstance.getAllAccounts()[0])

    // await msalInstance.logout(logoutRequest);
    console.log('Signed out successfully')
  }

  // if(currentAccount==null && isCheckLogout){
  //   window.location.href = 'http://localhost:3011'
  //   setIsCheckLogout(false)
  // }
  // console.log('sdadasdasdasdasdsad::', isCheckLogout)
  // useEffect(()=>{
  //   if(currentAccount==null && isCheckLogout){
  //     window.location.href = 'http://localhost:3011'
  //     setIsCheckLogout(false)
  //   }
  // },[currentAccount,isCheckLogout])
  // Example usage
  // manishsoni@dikoniatest5gmail.onmicrosoft.com
  // dikoniatest5@gmail.com
  useEffect(() => {
    let getazureLogoutcredential: any = localStorage.getItem('azureLogoutcredential')
    const currentAccount = JSON.parse(getazureLogoutcredential)
    console.log('qweasdasdsadsad::', currentAccount)
    if (checkLogout && currentAccount.account !== null) {
      // let currentAccount: any = {
      //   homeAccountId: '00000000-0000-0000-32e4-d717102c9f2b.9188040d-6c67-4c5b-b112-36a304b66dad',
      //   environment: 'login.windows.net',
      //   tenantId: '9eb2f756-f10a-4dc1-b735-3fd1ff15b469',
      //   username: 'manish@mail.in',
      //   localAccountId: '9fe12e4b-fbec-4401-83d8-3ef278a7497a',
      //   name: 'Manish Soni',
      //   authorityType: 'MSSTS',
      //   tenantProfiles: {},
      //   idTokenClaims: {
      //     aud: 'fff6db42-6c06-4093-9ff0-9ee912f0027d',
      //     iss: 'https://login.microsoftonline.com/9eb2f756-f10a-4dc1-b735-3fd1ff15b469/v2.0',
      //     iat: 1718968599,
      //     nbf: 1718968599,
      //     exp: 1718972499,
      //     idp: 'https://sts.windows.net/9188040d-6c67-4c5b-b112-36a304b66dad/',
      //     name: 'Manish Soni',
      //     nonce: '01903a87-cf48-7f25-8224-587f169192ee',
      //     oid: '9fe12e4b-fbec-4401-83d8-3ef278a7497a',
      //     preferred_username: 'manish@mail.in',
      //     rh: '0.AT0AVveyngrxwU23NT_R_xW0aULb9v8GbJNAn_Ce6RLwAn2hANQ.',
      //     sub: '6Y7WCreHKGwvjCF73iYSzIP5RB20GCV-E73c5junxzE',
      //     tid: '9eb2f756-f10a-4dc1-b735-3fd1ff15b469',
      //     uti: 'YNVz9yPJSUWz8vAOR1SMAA',
      //     ver: '2.0',
      //   },
      //   idToken:
      //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InE3UDFOdnh1R1F3RE4yVGFpTW92alo4YVp3cyJ9.eyJhdWQiOiJmZmY2ZGI0Mi02YzA2LTQwOTMtOWZmMC05ZWU5MTJmMDAyN2QiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vOWViMmY3NTYtZjEwYS00ZGMxLWI3MzUtM2ZkMWZmMTViNDY5L3YyLjAiLCJpYXQiOjE3MTg5Njg1OTksIm5iZiI6MTcxODk2ODU5OSwiZXhwIjoxNzE4OTcyNDk5LCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC85MTg4MDQwZC02YzY3LTRjNWItYjExMi0zNmEzMDRiNjZkYWQvIiwibmFtZSI6Ik1hbmlzaCBTb25pIiwibm9uY2UiOiIwMTkwM2E4Ny1jZjQ4LTdmMjUtODIyNC01ODdmMTY5MTkyZWUiLCJvaWQiOiI5ZmUxMmU0Yi1mYmVjLTQ0MDEtODNkOC0zZWYyNzhhNzQ5N2EiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJtYW5pc2hAbWFpbC5pbiIsInJoIjoiMC5BVDBBVnZleW5ncnh3VTIzTlRfUl94VzBhVUxiOXY4R2JKTkFuX0NlNlJMd0FuMmhBTlEuIiwic3ViIjoiNlk3V0NyZUhLR3d2akNGNzNpWVN6SVA1UkIyMEdDVi1FNzNjNWp1bnh6RSIsInRpZCI6IjllYjJmNzU2LWYxMGEtNGRjMS1iNzM1LTNmZDFmZjE1YjQ2OSIsInV0aSI6IllOVno5eVBKU1VXejh2QU9SMVNNQUEiLCJ2ZXIiOiIyLjAifQ.t1wC8bMSIpZ4L53ZVPuAIZysHmfHCytAbRg9tKN3Ak0SCDcvcrQ8LFB5EZEPWYd08oqJG0yYrqKZoQGiTHxFogEGBvRZX5iy06VragFXD98Sq4GRFC0BtC34w4xEXJjNb83h-HLGP8lz9w6ZIvsXcRiqB7rpONUmTWIyCyx1ycd-ySDzX4DvZTF-sce0Q7kw_IZdY2ruW7tH5rAMs1Lj_nMvq4qhs15qPplyJHcBHbqmK6fpf23gg3drTL-cV6joqsB58rTRgYlZ9IkpYrexW2amcgzeq3B1ienmkW-_PZKJhjVtoB22WDy220_Gsy5b1mOZJDtMWv55fqf0GncxkA',
      // }

      const handleLogout = async () => {
        if (logoutInProgress) {
          console.log('Logout already in progress.')
          return
        }

        setLogoutInProgress(true)

        const logoutRequest = {
          account: currentAccount.account, // Assuming there's only one account in this example
          // postLogoutRedirectUri: 'http://localhost:3011',
          postLogoutRedirectUri:  process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, ''),
        }

        try {
          // await instance.logoutPopup(logoutRequest)
          //  console.log("dhgjdfhjiuyiyui::::::::<><><>>",check)
          // await pca.handleRedirectPromise();
          setIsCheckLogout(true)
          setCheckLogout(false)
          saveAuth(undefined)
          setCurrentUser(undefined)
          localStorage.removeItem('microsoftAzure')
          localStorage.removeItem('azureLogoutcredential')
          window.location.href = "/"
        } catch (error) {
          setCheckLogout(false)
          console.error('Logout error:', error)
        } finally {
          setCheckLogout(false)
          setLogoutInProgress(false)
        }
       
      }
      handleLogout()
    }
    // let account: any = {
    //   account: 'manish@mail.in',
    //   postLogoutRedirectUri: 'http://localhost:3011', // Redirect URI after logout});}
    // }

    // Extract login hint to use as logout hint
    // const logoutHint = currentAccount.idTokenClaims.login_hint
    // await msalInstance.logoutPopup({logoutHint: logoutHint})
    // async function fetchData() {
    // let qww =  msalInstance.logoutRedirect({account: currentAccount})
    //  msalInstance.logout()

    //   console.log("Asdadasdasdasd::",qww)
    // }

    // fetchData()

    // localStorage.clear()

    // instance.logout()
    // const handleLogout = async () => {
    //   const logoutRequest = {
    //     account: currentAccount, // Assuming there's only one account in this example
    //     postLogoutRedirectUri: 'http://localhost:3011', // Redirect URI after logout
    //   };

    //   try {
    //     await instance.logoutPopup(logoutRequest);
    //   } catch (error) {
    //     console.error('Logout error:', error);
    //   }
    // };
    // handleLogout()
    // window.location.href = 'http://localhost:3011'

    //  const d= axios.post(`https://login.microsoftonline.com/9eb2f756-f10a-4dc1-b735-3fd1ff15b469/oauth2/v2.0/logoutsession`)
    //  console.log("d",d)
    // saveAuth(undefined)
    // setCurrentUser(undefined)
  }, [checkLogout])
  console.log('Sdasdasdasdasd::', checkLogout)
  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken()
          if (data) {
            setCurrentUser(createUserWithPermissions(data))
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout('false')
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser(auth.access_token)
    } else {
      logout('false')
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export function createUserWithPermissions(user: UserModel | undefined): UserModel | undefined {
  if (user === undefined) {
    return undefined
  }

  const userWithPermissions: UserModel = {
    ...user,
    hasPermission(permission: string) {
      if (!this.roles) {
        return false // If roles are not defined, the user has no permissions
      }
      for (const role of this.roles) {
        if (
          role.id === 1 ||
          (role.permissions && role.permissions.some((p) => p.value === permission))
        ) {
          return true // User has the required permission
        }
      }
      return false // User doesn't have the required permission
    },
  }
  return userWithPermissions
}

export {AuthProvider, AuthInit, useAuth}