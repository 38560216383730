import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import 'react-tooltip/dist/react-tooltip.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { Provider, useDispatch } from 'react-redux'
import store from './app/redux/store'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider, useMsal } from '@azure/msal-react'
import { useState, useEffect } from 'react'
import { UpdateAzureIdOpenAction } from './app/redux/azureIdOpen'

setupAxios(axios)
Chart.register(...registerables)
 const url_api =  process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, '')
// const url_api = process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, '') + '/'

let tenantid
let clientid
const storedAzureCredentials = localStorage.getItem('microsoftAzure')
if (storedAzureCredentials) {
  const azureCredentials = JSON.parse(storedAzureCredentials)
  tenantid = azureCredentials.tenantId
  clientid = azureCredentials.clientId
}


const queryClient = new QueryClient()
const container = document.getElementById('root')


const AppWrapper = () => {
  const {instance, accounts, inProgress}: {instance: any; accounts: any; inProgress: any} =useMsal()
  const [rerender, setRerender] = useState(false)
  const forceRerender = (data) => {
    tenantid=data.tenantId
    clientid=data.clientId
    setRerender(prev => !prev)
  }

  useEffect(() => {
    console.log('Component rerendered',rerender)
  }, [rerender])

  const msalConfig = {
    auth: {
      // clientId: clientid || 'fff6db42-6c06-4093-9ff0-9ee912f0027d',
      // authority: `https://login.microsoftonline.com/${tenantid || '9eb2f756-f10a-4dc1-b735-3fd1ff15b469'}`,
      clientId: clientid,
      authority: `https://login.microsoftonline.com/${tenantid}`,
      // redirectUri: 'http://localhost:3011',
      // postLogoutRedirectUri: 'http://localhost:3011',
      redirectUri: url_api,
      postLogoutRedirectUri : url_api
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  }
  const msalInstance = new PublicClientApplication(msalConfig)
    // console.log("sdasdasdasda::",data)

    // useEffect(() => {
    //   const fetchData = async () => {
    //     if (rerender) {
    //       try {
    //         let azureToken = await instance.loginPopup()
    //         console.log('Azure Token:', azureToken)
    //         // Further logic with azureToken
    //       } catch (error) {
    //         console.error('Error fetching Azure token:', error)
    //       }
    //     }
    //   }
    //   if (rerender) {
    //     fetchData()
    //   }
    // }, [rerender])
    console.log("gshsghd",process.env.REACT_APP_KT_API_URL?.replace(/\/api$/, '') + '/'
  )
  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <MetronicI18nProvider>
            <AuthProvider>
              <AppRoutes forceRerender={forceRerender} rerender={rerender}/>
            </AuthProvider>
          </MetronicI18nProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MsalProvider>
    </Provider>
  )
}

if (container) {
  createRoot(container).render(<AppWrapper />)
}