// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const AzureId = createSlice({
  name:"productAction",
  initialState:"",
  reducers:{
    UpdateAzureIdAction(state,action){
      return (action.payload)
    },
    ResetUpdateAzureIdAction(){
      return ""
    }
  }
}) 
export default AzureId.reducer;
export const {UpdateAzureIdAction,ResetUpdateAzureIdAction}= AzureId.actions;