import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Navigate, useSearchParams, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword, verifyResetPasswordLink} from '../core/_requests'
import {LayoutSplashScreen} from "../../../../_metronic/layout/core";

const initialValues = {
    new_password: '',
    confirm_password: ''
}

const resetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password')], 'Passwords must match')
        .required('Confirm Password is required'),
})

export function ResetPassword() {
    const [params] = useSearchParams();
    const token = params.get('token');
    const [checkingUrlValidity, setCheckingUrlValidity] = useState(true);
    const [isValidUrl, setIsValidUrl] = useState(false);
    const submitBtnRef = useRef<HTMLButtonElement | null>(null);
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            submitBtnRef.current?.setAttribute('data-kt-indicator', 'on');
            setHasErrors(undefined)
            setTimeout(() => {
                resetPassword(values.new_password, token ?? '')
                    .then(({data}) => {
                        setHasErrors(false)
                        submitBtnRef.current?.removeAttribute('data-kt-indicator');
                        setTimeout(() => {
                            navigate('/auth/login');
                        }, 3000);
                    })
                    .catch((e) => {
                        submitBtnRef.current?.removeAttribute('data-kt-indicator');
                        setSubmitting(false)
                        setHasErrors(true)
                        if (e.response.status === 404) {
                            setStatus('The login detail is incorrect')
                        } else {
                            setStatus('Email not registered')
                        }

                    })
            }, 1000)
        },
    })

    useEffect(() => {
        verifyResetPasswordLink(token ?? '')
            .then((data) => {
                setIsValidUrl(true);
                setCheckingUrlValidity(false);
            })
            .catch((e) => {
                if (e.response.status === 403) {
                    setIsValidUrl(false);
                    setCheckingUrlValidity(false);
                }

            })
    }, [])

    if (token === null) {
        return(
            <Navigate to={"/error/404"} />
        )
    }

    if (checkingUrlValidity) {
        return (
            <LayoutSplashScreen/>
        );
    }

    if(!checkingUrlValidity && !isValidUrl){
        return(
            <Navigate to={"/error/expired-url"} />
        )
    }

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                {/*<div className='text-gray-500 fw-semibold fs-6'>*/}
                {/*    Enter your email to reset your password.*/}
                {/*</div>*/}
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        Sorry, looks like there are some errors detected, please try again.
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Password is Successfully Changed. Login Now</div>
                </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
                <input
                    type='password'
                    placeholder='New Password'
                    autoComplete='off'
                    {...formik.getFieldProps('new_password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.new_password && formik.errors.new_password},
                        {
                            'is-valid': formik.touched.new_password && !formik.errors.new_password,
                        }
                    )}
                />
                {formik.touched.new_password && formik.errors.new_password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.new_password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Confirm Password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirm_password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password},
                        {
                            'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                        }
                    )}
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirm_password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button ref={submitBtnRef} type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Submit</span>

                        <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>

                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}
