// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import azureId from './azureId';
import azureIdOpen from './azureIdOpen';
const store = configureStore({
  reducer: {
    azureId:azureId,
    azureIdOpen:azureIdOpen
    // Add more reducers if needed
  }
});
export default store;
