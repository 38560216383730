import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const MasterLayout = () => {
    const location = useLocation()

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])

    return (
        <PageDataProvider>
            <div className='page d-flex flex-row flex-column-fluid'>
                <AsideDefault/>
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <HeaderWrapper/>

                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            <Content>
                                <Outlet/>
                            </Content>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>

            {/* begin:: Drawers */}
            <ActivityDrawer/>
            {/*<RightToolbar />*/}
            {/*  Below Component is used in AppChat*/}
            <DrawerMessenger/>
            {/* end:: Drawers */}

            {/* begin:: Modals */}
            <InviteUsers/>
            <UpgradePlan/>
            {/* end:: Modals */}
            <ScrollTop/>

            {/* begin:: Toast Container */}
            <ToastContainer/>
            {/* begin:: Toast Container */}
        </PageDataProvider>
    )
}

export {MasterLayout}
