/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {useDispatch, useSelector} from 'react-redux'
import { UpdateAzureIdOpenAction } from '../redux/azureIdOpen'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
interface AppRoutesProps {
  forceRerender: any // You can replace 'any' with the appropriate type if known
  rerender:any
}
const AppRoutes: FC<AppRoutesProps> = ({forceRerender,rerender}) => {
  const data = useSelector((state: any) => state.azureId)
  const dispatch=useDispatch()
  useEffect(() => {
    if (data?.clientId) {
      forceRerender(data)
    }
  }, [data])
  useEffect(()=>{
    if(rerender){
      dispatch(UpdateAzureIdOpenAction(true))
    }
  },[rerender])
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
